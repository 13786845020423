import { TFunction } from 'i18next';

import Chip from '@mui/material/Chip';

import { StatusProps } from 'constants/requestDetails';

export default function getRequestStatusChip(
  value: unknown,
  t: TFunction<'translation', string>
) {
  if (value || value === 0) {
    const status = StatusProps[Number(value) as keyof typeof StatusProps]?.key;

    return (
      <Chip
        variant='outlined'
        className={`work-order-line-status--${value}--cell`}
        label={t(`requestStatuses.${status}`)}
      />
    );
  }

  return null;
}
