import { VatDetails } from 'types/serviceRequest';

import { Typography } from '@mui/material';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import { styled } from '@mui/material/styles';

import Row from 'app/shared/components/Row';

import { useAppTranslation } from 'hooks/useAppTranslation';

import { VatTotalItemProps, getVatTotalItems } from './getVatTotalItems';

interface VatTotalItemsBoxProps {
  anchorEl: null | HTMLElement;
  handleClose: () => void;
  vatId: string;
  vatDetails: VatDetails;
  curActive: null | string;
}

const StyledVatTotalRow = styled(Row)({
  flexDirection: 'column',
  padding: '10px',

  '&>h2': {
    marginBottom: '10px',
    fontWeight: 700,
    fontSize: '18px',
    lineHeight: 'normal',
  },

  p: {
    fontSize: '14px',
    marginRight: 5,
  },

  hr: {
    margin: '8px 0',
  },

  '.MuiListItem-root': {
    display: 'flex',
    justifyContent: 'space-between',
  },
});

const VatTotalItemsBox = ({
  anchorEl,
  handleClose,
  vatId,
  vatDetails,
  curActive,
}: VatTotalItemsBoxProps) => {
  const { t } = useAppTranslation('requestDetailsSection');
  return anchorEl ? (
    <Popper id={vatId} open={curActive === vatId} anchorEl={anchorEl}>
      <Paper>
        <ClickAwayListener onClickAway={handleClose}>
          <StyledVatTotalRow>
            <Typography component='h2'>{t('totalLabel')}</Typography>
            <List>
              {getVatTotalItems(t, vatDetails).map(
                ({ label, value }: VatTotalItemProps, idx: number) => {
                  if (label === 'divider') {
                    return <Divider key={`${label}-${idx}`} />;
                  }

                  return (
                    <ListItem disablePadding key={`${label}-${value}-${idx}`}>
                      <Typography>{label}</Typography>
                      <Typography>{value}</Typography>
                    </ListItem>
                  );
                }
              )}
            </List>
          </StyledVatTotalRow>
        </ClickAwayListener>
      </Paper>
    </Popper>
  ) : null;
};

export default VatTotalItemsBox;
