import { Fragment, ReactNode, useCallback, useMemo } from 'react';
import short from 'short-uuid';
import { DetailsProps } from 'types/requestDetails';
import { WorkOrderLine } from 'types/serviceRequest';

import { Typography } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { TableCellProps } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { WithLoadingPropTypes } from 'app/hocs/withLoading';
import Loader from 'app/shared/components/Loader';

import { useSelector } from 'hooks/global';
import { useAppTranslation } from 'hooks/useAppTranslation';

import { selectRequestDetails } from 'store/selectors/serviceRequests.selector';

import { WorkOrderType } from 'constants/requestDetails';

import formatValueToCurrency from 'utilities/formatValueToCurrency';

const Details = ({
  isLoading,
  t,
  totalValues,
  isTotalDetails,
  tableConfig,
  getCellContentValue,
}: DetailsProps & Partial<WithLoadingPropTypes>) => {
  const { amount } = useSelector(selectRequestDetails);
  const { t: translateStatus } = useAppTranslation('dashboard');

  const tableKeys = useMemo(() => Object.keys(tableConfig), [tableConfig]);

  const renderTotalsRow = useCallback(
    () =>
      isTotalDetails && amount ? (
        <TableRow className='footer-row' key='footer_row_uniq_key'>
          <TableCell
            key='table_footer_total_label_uniq_key'
            colSpan={7}
            align='right'
          >
            {t('tableFooter.total')}
          </TableCell>
          <TableCell
            key='table_footer_total_amount_key_uniq'
            colSpan={2}
            align='center'
          >
            {formatValueToCurrency(amount)}
          </TableCell>
        </TableRow>
      ) : null,
    [amount, isTotalDetails, t]
  );

  const renderTableCells = useCallback(
    (getValue: (key: string) => ReactNode, rowIndex?: number) =>
      tableKeys.map((key, idx: number) => {
        const celProps = tableConfig[
          key as keyof typeof tableConfig
        ] as TableCellProps;

        return (
          <TableCell
            key={`row_index_${rowIndex}__${idx}__${key.toString()}`}
            {...celProps}
          >
            {getValue(key)}
          </TableCell>
        );
      }),
    [tableConfig, tableKeys]
  );

  const renderWorkOrderLineRows = useCallback(
    (lines: Array<Partial<WorkOrderLine>>): Array<ReactNode> | null => {
      return lines?.map((line: Partial<WorkOrderLine>, index: number) => {
        const { childs, costs } = line;

        const isEmptyRow = line.type === WorkOrderType.Empty;
        const shouldShowRow =
          (line.isPlaceholder && !line.childs?.length) || !line.isPlaceholder;

        const extendedLine = {
          ...line,
          ...costs,
          childs,
        };

        return (
          <Fragment key={`${short.generate()}_Fragment_part_isLoading`}>
            {shouldShowRow && (
              <TableRow
                className={isEmptyRow ? 'is-empty-row' : ''}
                key={`${index}_uniq_key_${short.generate()}_${line.parentId}`}
              >
                {renderTableCells(
                  getCellContentValue(extendedLine, translateStatus),
                  index
                )}
              </TableRow>
            )}

            {childs?.length ? renderWorkOrderLineRows(childs) : null}
          </Fragment>
        );
      });
    },
    [getCellContentValue, renderTableCells, translateStatus]
  );

  return (
    <TableContainer component={Paper}>
      <Table className='rob-totalen-table' aria-label='rob-totalen-table'>
        <TableHead>
          <TableRow>
            {renderTableCells(key =>
              t(`tableHeader.${key as keyof typeof tableConfig}`)
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {isLoading ? (
            <TableRow key={`table_cell_isLoading_${isLoading}`}>
              <TableCell colSpan={tableKeys.length} align='center'>
                <Loader width='50px' height='50px' />
              </TableCell>
            </TableRow>
          ) : totalValues.length ? (
            totalValues.map((row, rowIndex) => {
              const { workOrderLines } = row;

              const isWorkorderLineVisible = !isTotalDetails && workOrderLines;

              const rowClassName = !isTotalDetails ? 'rob-row' : '';

              return (
                <Fragment
                  key={`${short.generate()}_Fragment_part_isLoading_${isLoading}`}
                >
                  <TableRow className={rowClassName} key={rowIndex}>
                    {!!row &&
                      renderTableCells(
                        getCellContentValue(row, translateStatus),
                        rowIndex
                      )}
                  </TableRow>

                  {isWorkorderLineVisible &&
                    renderWorkOrderLineRows(workOrderLines)}
                </Fragment>
              );
            })
          ) : (
            <TableRow key={`empty_data_table_row_key_isLoading_${isLoading}`}>
              <TableCell
                key='empty_data_table_cell_key'
                colSpan={tableKeys.length}
                align='center'
              >
                <Typography align='center'>{t('emptyData')}</Typography>
              </TableCell>
            </TableRow>
          )}
          {renderTotalsRow()}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default Details;
