import { AsyncThunk } from '@reduxjs/toolkit';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AnyAction } from 'redux';
import Path from 'routes/path';
import short from 'short-uuid';
import { RootState } from 'types/global';
import {
  GetRequestParams,
  Pagination,
  RequestItem,
} from 'types/serviceRequest';

import { useAuth0 } from '@auth0/auth0-react';

import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import {
  DataGridPro,
  GridColDef,
  GridFilterModel,
  GridLogicOperator,
  GridRenderCellParams,
  GridRowParams,
  GridSortModel,
} from '@mui/x-data-grid-pro';

import withLoading, { WithLoadingPropTypes } from 'app/hocs/withLoading';
import EditButton from 'app/shared/components/EditButton';
import Row from 'app/shared/components/Row';
import Search from 'app/shared/components/Search';

import { useDispatch, useSelector } from '../../../hooks/global';
import { useAppTranslation } from '../../../hooks/useAppTranslation';

import { selectCurrentLocaleId } from '../../../store/selectors/userSettings.selector';
import { resetSearch } from '../../../store/slices/search.slice';
import {
  selectIsSearchLoading,
  selectSearchValue,
} from 'store/selectors/search.selector';

import { GREEN_BLUE } from 'constants/colors';
import { ENTER_KEY, ROWS_PER_PAGE_OPTIONS } from 'constants/general';
import { StatusProps } from 'constants/requestDetails';
import { TestId } from 'constants/testIds';

import {
  ACTUAL_PAGE,
  ACTUAL_PER_PAGE,
  HISTORICAL_PAGE,
  HISTORICAL_PER_PAGE,
  getStorageItem,
  removeStorageItem,
  setStorageItem,
} from 'utilities/storageUtils';

import { ROUTING_PARAMS } from '../../../routes/path';
import ServiceRequestsContainer from './ServiceRequestsContainer';
import DataGridFooter from './components/DataGridFooter';
import { getColumns } from './getColumns';
import {
  getCellClassName,
  getDataGridLocale,
  getFilterParams,
  getSortParams,
} from './utils';

interface GetRequestListParams {
  customFilterModel?: GridFilterModel | null;
  customSortModel?: GridSortModel | null;
  customPerPage?: number;
  customPage?: number;
  searchValue?: string;
}

interface ListProps extends WithLoadingPropTypes {
  getList: AsyncThunk<unknown, GetRequestParams, Record<string, never>>;
  resetList: () => AnyAction;
  dataGridKey: string;
  setDataGridKey: (s: string) => void;
  selectPagination: (state: RootState) => Pagination;
  selectRows: (state: RootState) => (RequestItem | undefined)[];
  totalDetailPath: 'requestDetails' | 'historyRequestDetails';
  pageTitle: string;
  isHistoryView?: boolean;
}

const ServiceRequestsList = ({
  isLoading,
  getList,
  resetList,
  setIsLoading,
  selectRows,
  selectPagination,
  totalDetailPath,
  pageTitle,
  dataGridKey,
  setDataGridKey,
  isHistoryView,
}: ListProps) => {
  const { t } = useAppTranslation('dashboard');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();

  const [filterModel, setFilterModel] = useState<
    GridFilterModel | null | undefined
  >();
  const [sortModel, setSortModel] = useState<
    GridSortModel | null | undefined
  >();

  const curLocale = useSelector(selectCurrentLocaleId);
  const { totalItems, totalPages } = useSelector(selectPagination);
  const isSearchLoading = useSelector(selectIsSearchLoading);
  const rows = useSelector(selectRows);
  const storedSearchValue = useSelector(selectSearchValue);

  const storagePerPageKey = isHistoryView
    ? HISTORICAL_PER_PAGE
    : ACTUAL_PER_PAGE;
  const storagePageKey = isHistoryView ? HISTORICAL_PAGE : ACTUAL_PAGE;

  const currentPage = getStorageItem(storagePageKey) || 0;
  const rowsPerPage =
    getStorageItem(storagePerPageKey) || ROWS_PER_PAGE_OPTIONS[1];

  const handleGetRequestsList = useCallback(
    async (params?: GetRequestListParams) => {
      setIsLoading(true);
      const {
        customPage,
        customPerPage,
        customFilterModel,
        customSortModel,
        searchValue,
      } = params || {};

      const token = await getAccessTokenSilently();

      const initBody = {
        searchValue: searchValue || storedSearchValue || '',
        perPage: customPerPage || rowsPerPage,
        page: customPage || currentPage + 1,
      };

      const isFilterModelExist =
        params && Object.hasOwn(params, 'customFilterModel');
      const isSortModelExist =
        params && Object.hasOwn(params, 'customSortModel');

      const fModel = isFilterModelExist ? customFilterModel : filterModel;
      const sModel = isSortModelExist ? customSortModel : sortModel;

      const filteringParams = getFilterParams(fModel);
      const sortParams = getSortParams(sModel);

      const mergedBody = {
        ...initBody,
        ...(fModel?.items?.length ? filteringParams : {}),
        ...(sModel?.length ? sortParams : {}),
      };

      const param = {
        body: mergedBody,
        config: {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      };

      await dispatch(getList(param)).finally(() => setIsLoading(false));
    },
    [
      setIsLoading,
      getAccessTokenSilently,
      storedSearchValue,
      rowsPerPage,
      currentPage,
      filterModel,
      sortModel,
      dispatch,
      getList,
    ]
  );

  const handleKeyUp = useCallback(
    async (event: KeyboardEvent) => {
      const customFilterPanel = document.getElementById('custom-filter-panel');

      if (event.key === ENTER_KEY && customFilterPanel) {
        handleGetRequestsList();
      }
    },
    [handleGetRequestsList]
  );

  useEffect(() => {
    document.addEventListener('keyup', handleKeyUp, false);

    return () => {
      document.removeEventListener('keyup', handleKeyUp);
    };
  }, [handleKeyUp]);

  useEffect(() => {
    const removedKey = isHistoryView ? ACTUAL_PAGE : HISTORICAL_PAGE;
    removeStorageItem(removedKey);
  }, [isHistoryView]);

  useEffect(() => {
    setIsLoading(true);

    const pageSetting = currentPage ? currentPage + 1 : 1;
    getAccessTokenSilently().then(token => {
      const param = {
        body: {
          page: pageSetting,
          perPage: rowsPerPage,
        },
        config: {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      };

      dispatch(getList(param)).finally(() => setIsLoading(false));
    });

    return () => {
      dispatch(resetList());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleFilterModelChange = useCallback(
    (model: GridFilterModel) => {
      const isModelChanged =
        (filterModel?.items?.length || 0) > model.items?.length;

      if (isModelChanged) {
        handleGetRequestsList({ customFilterModel: model });
      }

      setFilterModel(model);
    },
    [filterModel, handleGetRequestsList]
  );

  const handleSortModelChange = useCallback(
    (model: GridSortModel) => {
      handleGetRequestsList({ customSortModel: model });

      setSortModel(model);
    },
    [handleGetRequestsList]
  );

  const handleSearch = useCallback(
    (value: string) => {
      setFilterModel(null);
      setSortModel(null);
      setStorageItem(0, storagePageKey);
      handleGetRequestsList({
        searchValue: value,
        customFilterModel: null,
        customSortModel: null,
        customPage: 1,
      }).finally(() => setDataGridKey(short.generate()));
    },
    [handleGetRequestsList, setDataGridKey, storagePageKey]
  );

  const handleOpenTotalDetails = useCallback(
    ({ id }: GridRowParams) => {
      const path = Path.generate(Path[totalDetailPath].total, {
        [ROUTING_PARAMS.serviceRequestId]: id,
      });

      navigate(path, { replace: false, state: { name: path } });
    },
    [navigate, totalDetailPath]
  );

  const handleChangePage = useCallback(
    (__: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
      setStorageItem(newPage, storagePageKey);
      handleGetRequestsList({
        customPage: newPage + 1,
      });
    },
    [handleGetRequestsList, storagePageKey]
  );

  const handleChangeRowsPerPage = useCallback(
    ({ target }: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const customPerPage = parseInt(target.value);
      setStorageItem(0, storagePageKey);
      setStorageItem(customPerPage, storagePerPageKey);
      handleGetRequestsList({
        customPerPage,
        customPage: 1,
      });
    },
    [handleGetRequestsList, storagePageKey, storagePerPageKey]
  );

  const handleResetDataGrid = useCallback(() => {
    dispatch(resetSearch());
    setFilterModel(null);
    setSortModel(null);
    setStorageItem(ROWS_PER_PAGE_OPTIONS[1], storagePerPageKey);
    setStorageItem(0, storagePageKey);
    handleGetRequestsList({
      customFilterModel: null,
      customSortModel: null,
      customPerPage: ROWS_PER_PAGE_OPTIONS[1],
      customPage: 1,
    }).finally(() => setDataGridKey(short.generate()));
  }, [
    dispatch,
    storagePerPageKey,
    storagePageKey,
    handleGetRequestsList,
    setDataGridKey,
  ]);

  const renderDetailsCell = useCallback(
    ({ id }: GridRenderCellParams) => {
      const onClick = () => handleOpenTotalDetails({ id } as GridRowParams);

      return <EditButton onClick={onClick} />;
    },
    [handleOpenTotalDetails]
  );

  const renderStatusCell = useCallback(
    ({ value }: GridRenderCellParams) => {
      const status =
        StatusProps[Number(value) as keyof typeof StatusProps]?.key;

      return <div>{t(`requestStatuses.${status}`)}</div>;
    },
    [t]
  );

  const columns = useMemo(
    () =>
      getColumns({
        renderDetailsCell,
        renderStatusCell,
        isHistory: !!isHistoryView,
      }).map((column: GridColDef) => ({
        ...column,
        headerName: t(`columns.headerName.${column.field}`),
        description: t(`columns.description.${column.field}`),
      })),
    [renderDetailsCell, renderStatusCell, isHistoryView, t]
  );

  const components = useMemo(
    () => ({
      noRowsOverlay: () => (
        <Stack className='no-data-element'>{t('noData')}</Stack>
      ),
      footer: () => (
        <DataGridFooter
          handleResetDataGrid={handleResetDataGrid}
          currentPage={currentPage}
          totalItems={totalItems}
          totalPages={totalPages}
          rowsPerPage={rowsPerPage}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
        />
      ),
    }),
    [
      t,
      handleResetDataGrid,
      currentPage,
      totalItems,
      totalPages,
      rowsPerPage,
      handleChangePage,
      handleChangeRowsPerPage,
    ]
  );

  return (
    <ServiceRequestsContainer disableGutters maxWidth={false}>
      <Row className='header-wrapper' variant='space-between'>
        <Box className='history-header'>
          <Typography data-testid={TestId.DASHBOARD_HEADER_TITLE}>
            {t(pageTitle)}
          </Typography>
          {isHistoryView && (
            <Chip sx={{ color: GREEN_BLUE }} label={t('historyChipLabel')} />
          )}
        </Box>
        <Search placeHolder={t('search')} handleSearch={handleSearch} />
      </Row>
      <Box className='data-grid-wrapper'>
        <DataGridPro
          autoHeight
          localeText={getDataGridLocale(curLocale)}
          loading={isLoading || isSearchLoading}
          key={dataGridKey}
          rows={rows}
          columns={columns}
          slots={components}
          paginationModel={{
            page: currentPage,
            pageSize: rowsPerPage,
          }}
          rowCount={totalItems}
          filterMode='server'
          sortingMode='server'
          paginationMode='server'
          slotProps={{
            filterPanel: {
              logicOperators: [GridLogicOperator.And],
              filterFormProps: {
                operatorInputProps: {
                  disabled: true,
                },
              },
            },
          }}
          onFilterModelChange={handleFilterModelChange}
          onSortModelChange={handleSortModelChange}
          getCellClassName={getCellClassName}
          pageSizeOptions={ROWS_PER_PAGE_OPTIONS}
          onRowClick={handleOpenTotalDetails}
        />
      </Box>
    </ServiceRequestsContainer>
  );
};

export default withLoading(ServiceRequestsList);
