import type { Namespace } from 'i18next';
import { UseTranslationOptions, useTranslation } from 'react-i18next';

import { useSelector } from 'hooks/global';

import { selectCurrentLocaleId } from '../store/selectors/userSettings.selector';

const DEFAULT_NS = 'translation';

export function useAppTranslation(
  prefix?: string,
  namespace?: Namespace,
  options?: UseTranslationOptions<string>
) {
  const curLocale = useSelector(selectCurrentLocaleId);
  const { t, ...rest } = useTranslation(DEFAULT_NS || namespace, {
    ...{
      lng: curLocale,
      ...options,
    },
    keyPrefix: prefix,
  });

  return {
    t,
    ...rest,
  };
}
